import { useState } from "react"
import './Caller.css'


export default function Caller() {
    const [country, setCountry] = useState('852')
    const [number, setNumber] = useState('')

    const handleCaller = () => {
        const fullNumber = `${country}${number}`;
        const whatsappURL = `whatsapp://send?phone=${fullNumber}`;
        window.location.href = whatsappURL;
    };

    return (
        <div className="app-container">
            <h1>WhatsApp Caller</h1>
            <div className="input-group">
                <label htmlFor="regionCode">地區代碼:</label>
                <input
                    type="text"
                    id="regionCode"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                />
            </div>
            <div className="input-group">
                <label htmlFor="phoneNumber">電話號碼:</label>
                <input
                    type="tel"
                    id="phoneNumber"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="輸入電話號碼"
                />
            </div>
            <button className="call-button" onClick={handleCaller}>
                Call WhatsApp
            </button>
        </div>
    )
}