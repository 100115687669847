

export default function Footer() {
    return (
        <footer>
            <div>
                <p>By Angus. All rights reserved.</p>
            </div>
        </footer>
    );
}
